<template>
  <aw-tabler :table="tableJSON">
    <template #drawercontent>
      <el-form
          ref="addForm"
          v-loading="loading"
          :model="info"
          label-width="100px"
      >
        <el-form-item label="名称" prop="name" :rules="{required: true, message: '请输入名称'}">
          <el-input v-model.trim="info.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
              v-model.trim="info.remark"
              type="textarea"
              maxlength="180"
              show-word-limit
              placeholder="请输入备注"
          />
        </el-form-item>
        <el-form-item label="">
          <div class="btns">
            <el-button class="btn" type="primary" @click="storeInfo">保存</el-button>
            <el-button class="btn" @click="cancelDrawer">取消</el-button>
          </div>
        </el-form-item>
      </el-form>
    </template>
  </aw-tabler>
</template>

<script setup>
import {ref, reactive, nextTick} from 'vue'
import { tableColumn } from '@/assets/js/extend/tableColumn'
import {http, tool} from "@/assets/js";
import {useRoute} from 'vue-router'
import {ElMessage} from 'element-plus'

const route = useRoute()
// 操作权限
const powerlimits = reactive(tool.getArrayToOneChat(route.meta.roles,'component'))

const loading = ref(false)
const addForm = ref()
const info = ref({
  name: '',
  remark: ''
})

const tableJSON = ref({
  title: '推荐奖励',
  loading: false,
  datas: [],
  columns: [
    ...tableColumn([
      {
        title: 'ID',
        key: 'id',
        width: '60'
      },
      {
        title: '名称',
        key: 'name',
        minWidth: '140'
      },
      {
        title: '备注',
        key: 'remark',
      },
      // {
      //   title: '操作时间',
      //   key: 'addtime',
      // },
      // {
      //   title: '操作人',
      //   key: 'truename',
      // }
    ]),
    {
      fixed: 'right',
      title: '操作',
      key: '',
      width: '100',
      buttons: [
        {
          name: '编辑',
          props: { type: 'primary' },
          show: () => powerlimits.includes('tjrjl_bj'),
          action: (data) => {
            tableJSON.value.drawer.title = '修改推荐人奖励'
            tableJSON.value.drawer.show = true
            nextTick(() => {
              info.value = {
                ...data,
              }
            })
          }
        }
      ]
    }
  ],
  page: {
    total: 0,
    page: 1,
    page_size: 10,
    sizeChange: (val) => {
      tableJSON.value.page.page_size = val
      tableJSON.value.page.page = 1
      tableJSON.value.action(tableJSON.value.filter)
    },
    currentChange: (val) => {
      tableJSON.value.page.page = val
      tableJSON.value.action(tableJSON.value.filter)
    }
  },
  action: async (params) => {
    tableJSON.value.loading = true
    if(powerlimits.includes('tjrjl_lb')) {
      let page = { page: tableJSON.value.page.page, page_size: tableJSON.value.page.page_size }
      let result = await http.post('/admin/agent/pushGiftIndex', { ...params, ...route.query, ...page })
      tableJSON.value.datas = result.data
      tableJSON.value.page.total = result.total || Infinity
      tableJSON.value.page.page = result.current_page
      tableJSON.value.page.page_size = result.per_page
    } else {
      tableJSON.value.datas = []
      tableJSON.value.page.total = Infinity
    }
    tableJSON.value.loading = false
    return
  },
  extras: [
    {
      name: '添加',
      props: { type: 'primary', plain: true, },
      show: () => powerlimits.includes('tjrjl_tj'),
      action: () => {
        tableJSON.value.drawer.show = true
        tableJSON.value.drawer.title = '添加推荐人奖励'
      }
    }
  ],
  drawer: reactive({
    show: false,
    title: '',
    size: '50%',
    close: () => {
      cancelDrawer()
    }
  })
})

const cancelDrawer = () => {
  addForm.value.resetFields()
  delete info.value.id
  tableJSON.value.drawer.show = false
  loading.value = false
}
const storeInfo = () => {
  addForm.value.validate((valid) => {
    if(valid) {
      loading.value = true
      http.post(info.value.id ? '/admin/agent/pushGiftEdit' : '/admin/agent/pushGiftAdd', info.value).then(res => {
        if(res) {
          if(res.code === 0) ElMessage.success(info.value.id ? '修改成功' : '添加成功')
          tableJSON.value.drawer.show = false
          reloadList()
          nextTick(() => {
            addForm.value.resetFields()
          })
        }
      }).finally(() => {
        loading.value = false
      })
    }
  })

  const reloadList = () => {
    tableJSON.value.page.page = 1
    tableJSON.value.action({})
  }
}
</script>
